import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import getLang from '../components/scripts/getLang'
import * as Styled from "../css/CardFlexStyle"
import PictureFilter from "./scripts/pictureFilter"
import CheckForHighlight from "./scripts/checkForHightlight"
import { SortBySeason2 } from "./scripts/SortBySeasons";
import { EnheterQuery } from './scripts/graphQlQuerys/enheterQuery'

export const BarLokalGraphql = props => {
  let { SearchKeyword, english, pageType} = props
  if (SearchKeyword === undefined) {
    SearchKeyword = ""
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi")
  let bar = getLang(english, EnheterQuery())
  bar = SortBySeason2(bar)
  bar = CheckForHighlight(pageType, bar)
    .filter(edges => {
      return edges.node.sida.includes("Bar")
    })
    .filter(bar => {
      let newString = bar.node.skord.toString()

      return newString.match(regex)
    })
    .map((item, index) => {
      let orderOne = "";
      if(item.node.highlight){
        orderOne = "orderOne"
      }
      return (
        <Link
          className={"textDecorationNone " + orderOne}
          key={index}
          to={`/bar/${item.node.pekaMotSlug ? item.node.pekaMotSlug : item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={PictureFilter('img2', item)}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      )
    })

  return (<Styled.CenterDiv>
    <Styled.FlexContainer>{bar}</Styled.FlexContainer>
  </Styled.CenterDiv>)
}
