import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import { BarLokalGraphql } from "../../components/barSearch";
import App from "../app";
import getLang from "../../components/scripts/getLang";
import Taggar from "../../components/taggar";
import Searchbar from "../../components/searchbar";
import Hero from '../../components/hero'

import { BarQuery } from "../../components/scripts/graphQlQuerys/barQuery";

const Drink = () => {
  const pageType = "Bar Alla";
  const [SearchKeyword, setSearchKeyword] = useState("");
  let [english] = useGlobal("English");
  let newData = getLang(english, BarQuery());
  let { Synligataggar,introText } = newData[0].node;

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "NewYear", newURL);
    }
  });

  return (
    <App>
      <SEO
        title={newData[0].node.seoTitel}
        description={newData[0].node.seoDescription}
      />
      <Hero introText={introText} heroTema={null} data={newData} img={'img1'} />
      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword}/>
      <Taggar mainPage="bar" subPage="Alla" data={Synligataggar} />
      <BarLokalGraphql SearchKeyword={SearchKeyword} pageType={pageType} english={english}/>
    </App>
  );
};

export default Drink;
